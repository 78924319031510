<template>
	<div class="w-full">
		<div v-if="!displayTableOnly" class="w-full sm:flex sm:items-center">
			<div class="sm:flex-auto">
				<Text size="xl" weight="semibold" color="gray-900" :content="title"/>
				<Text size="sm" color="gray-700" :content="description" class="mt-2"/>
			</div>
			<div v-if="btnText" class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
				<Button :content="btnText" variant="primary" :onClick="onClick"/>
			</div>
		</div>
		<div class="flex flex-col w-full mt-px">
			<div class="w-full">
				<div v-if="list.length > 0" class="min-w-full align-middle">
					<div class="shadow md:rounded-lg">
						<table class="min-w-full border-t">
							<thead  v-if="displayHeaders" class="bg-gray-50 divide-y divide-gray-300">
							<tr>
								<th v-for="(item, id) in headers" :key="id" scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 truncate">{{item.label}}</th>
								<th/>
							</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								<tr v-for="(item, id) in list" :key="id">
									<td v-for="(i, index) in headers" :key="index" class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
										<div v-if="i.name !== 'role' && i.name !== 'Relationship' && i.name !== 'Status'">
											{{item && item[i.name]}}
										</div>
										<Badge v-else :label="item && item[i.name]" px="3" py="1" bg-color="primary-500" custom-class="rounded-full" text-color="white" text-weight="md"/>
									</td>
									<td class="sticky right-0 relative py-4 pl-3 pr-2 text-right whitespace-nowrap sm:pr-6 w-20 h-full bg-white">
										<Text v-if="linkText" class="absolute -mt-2 border-b border-red-500 cursor-pointer right-6 w-fit" :content="linkText" color="red-500" hoverColor="red-600" size="sm" weight="medium"/>
										<slot name="multiselect" :id="id" :selectedItem="selectedItem" :setSelectedItem="(item) => selectedItem = item"></slot>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-else class="flex justify-center items-center w-full mt-4">
					<Empty v-bind="empty"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Button from '../../../atoms/Button/Button.vue'
import Link from '../../../atoms/Link/Link.vue'
import Text from '../../../atoms/Text/Text.vue'
import {computed} from 'vue'
import { capitalize } from 'lodash'
import Empty from '../../EmptyState/Simple/Simple.vue'
import Badge from '../../../molecules/Badge/Basic/Basic.vue'
/**
 * - Use it to show a simple table
 */
export default {
	components: {
		Button, Link, Text, Empty, Badge
	},
	data() {
		return {
			/**
			 * Generate dynamic headers according to list item
			 */
			headers: computed(() => {
					let items = [];
					Object.keys(this.list[0]).map(i => {
						let str = i.split(/(?=[A-Z])/);
						str = str.join(' ');
						items.push({name: i, label: capitalize(str)})
					})
					return [...items]
			}),
			modalView: false,
			selectedItem: null,
		}
	},
	props: {
		/**
		 * Use it for table list
		 */
		list: {
			type: Array,
			default: () => []
		},
		/**
		 * Use it for table title
		 */
		title: {
			type: String,
			default: ''
		},
		/**
		 * Use it for table description
		 */
		description: {
			type: String,
			default: ''
		},
		/**
		 * Use it for button text
		 */
		btnText: {
			type: String,
			default: ''
		},
		/**
		 * Use it for button text
		 */
		linkText: {
			type: String,
			default: ''
		},
		/**
		 * Use it for button action
		 */
		onClick: {
			type: Function,
			default: () => {}
		},
		/**
		 * Use it for header display
		 */
		displayHeaders: {
			type: Boolean,
			default: true
		},
		/**
		 * Use it for table display only
		 */
		displayTableOnly: {
			type: Boolean,
			default: false
		},
		empty: {
			type: Object,
			default: () => {}
		}
	}
}
</script>
